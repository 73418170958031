<template>
  <b-container fluid="">
    <b-row>
      <b-col sm="12" lg="12">
        <card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Tambah Anggaran Baru</h4>
          </template>
          <template v-slot:body>
            <form >
              <div class="form-row">
                <b-col md="12" class="mb-3">
                  <label for="provinsi">Provinsi</label>
                  <v-select @search="onProvinsiSearch" :filterable="false" :options="opt.provinsi" class="style-chooser" @input="provinsiSelected" > 
                    <template slot="no-options">
                      Cari...
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.name }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option" >
                      <div class="selected d-center">
                        {{ option.name }}
                      </div>
                    </template>
                  </v-select>
                </b-col>
                <b-col md="12" class="mb-3" v-show="show.kota">
                  <label for="kota">Kabupaten/Kota</label>
                  <Wilayah mode="kota" v-bind:opts="[]" :parentId=form.provinsi_id v-on:childToParent="onKotaSelected" />
                </b-col>
                <b-col md="12" class="mb-3" v-show="show.kecamatan">
                  <label for="kota">Kecamatan</label>
                  <Wilayah mode="kecamatan" v-bind:opts="[]" :parentId=form.kota_id v-on:childToParent="onKecamatanSelected" />
                </b-col>
                <b-col md="12" class="mb-3" v-show="show.desa">
                  <label for="kota">Desa</label>
                  <Wilayah mode="desa" v-bind:opts="[]" :parentId=form.kecamatan_id v-on:childToParent="onDesaSelected" />
                </b-col>
              </div>
            </form>
          </template>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import _ from 'lodash'
import { getDataService } from '../../../store/modules/crudservices'

export default {
  created(){
    document.title = "Master Wilayah Indonesia"
  },
  name:'WilayahIndex',
  data() {
    return {
      renderComponent: true,
      fromChild: '',
      opt:{
        provinsi:[],
        kota: [],
        kecamatan: [],
        desa:  []
      },
      show:{
        kota: false,
        kecamatan: false,
        desa: false
      },
      form:{
        provinsi_id: 0,
        kota: null,
        kota_id: 0,
        kecamatan_id: 0,
        
      },
      optionProvinsi:[]
    }
  },
  methods: {
    onProvinsiSearch (search, loading) {
      if(search.length) {
        const url = `/wilayah/provinsi?q=${search}`
        loading(true)
        this.fetchOptions(loading, url, this.opt.provinsi)
      }
    },
    provinsiSelected(value) {
      this.form.provinsi_id = value.id
      this.show.kota = true
      this.show.kecamatan = false
      this.show.desa = false
    },

    
    fetchOptions: _.debounce((loading, url, dt) => {
      getDataService(url).then(response => {
        dt = response.data
        console.log(dt)
        loading(false)
      })
    },350),


    onProvinsiSelected (value) {
      this.form.provinsi_id = value.id
      // document.querySelector('.vs__clear').click();
    },
    onKotaSelected (value) {
      this.form.kota_id = value.id
      this.show.kecamatan = true
      this.show.desa = false
    },
    onKecamatanSelected (value) {
      this.form.kecamatan_id = value
      this.show.desa = true
    },
    onDesaSelected (value) {
      this.form.kecamatan_id = value
    }
  }
}
</script>